<template>
  <div class="container" id="container">
    <div class="top">
      <a href="javascript:;" class="back" @click="back">返回</a>
      <h1>旅游快报</h1>
    </div>
    <!-- 轮播 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in slider" :key="index">
        <div v-if="item.interlinkage" class="box">
          <a @click="$router.push({path:'/outWindow', query:{ interlinkage: encodeURI(item.interlinkage)}})">
            <h1>{{item.description}}</h1>
            <van-image fit="fill" :src="item.imgUrl" class="recr_swiper_item_img">
              <template v-slot:error>加载失败</template>
            </van-image>
          </a>
        </div>
        <div v-else class="box">
          <h1>{{item.description}}</h1>
          <van-image fit="fill" :src="item.imgUrl" class="recr_swiper_item_img">
            <template v-slot:error>加载失败</template>
          </van-image>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="list">
      <div class="blockname">最新资讯</div>
      <div class="wrap">
        <a href="javascript:;" v-for="(item, index) in list" :key="index" @click="detail(item,index)">
          <div class="img" :style = '{ background:"url("+ item.cover +") no-repeat center center/cover"}' v-if="item.cover"></div>
          <div class="img" :style = '{ background:"url("+ background +") no-repeat center center/cover"}' v-else></div>
          <div class="text">
            <div class="title">{{item.title}}</div>
            <div class="brief" v-html="item.content.replace(/<.*?>/ig,'')"></div>
            <div class="time">{{item.createDate.substr(0,10)}}</div>
          </div>
        </a>
      </div>
      <div class="loading" v-show="loading">
        <img src="../../hotel/img/loading.png" alt="正在加载" />正在加载
      </div>
      <div class="nomore" v-show="nomore">没有更多了~</div>
    </div>
  </div>
</template>

<script>
  import { Swipe, SwipeItem, Image} from 'vant'
  export default {
    name: "index",
    components:{
      'van-swipe':Swipe,
      'van-image':Image,
      'van-swipe-item':SwipeItem,
    },
    data() {
      return {
        api: {
          banner: "/banner/queryBanner", // 轮播地址
          list: "/content/listTravelNewsFlash", // 列表地址
        },
        pageSize: 10, // 每页数量
        pageIndex: 1, // 当前页码
        totalPage: 0, // 页面总数
        loading: true, // 正在加载
        slider: [], // 轮播列表
        list: [], // 快讯列表
        nomore: false, //没有更多了
        background: require('../../hotel/img/news.png') // 默认背景图
      }
    },
    created() {

      this.getBanner(); // 获取轮播
      this.getList(); // 获取列表
    },
    mounted() {
      //监听scroll事件
      window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed() {
      //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      // 获取轮播
      getBanner() {
        let _this = this;
        this.$get({
          url: _this.api.banner,
          params: {
            position: 16,
          },
        }).then((rsp) => {
          _this.slider = rsp
        })
      },
      // 获取快报列表
      getList() {
        this.loading = true
        let _this = this;
        this.$get({
          url: _this.api.list,
          params: {
            pageSize: _this.pageSize,
            pageIndex: _this.pageIndex
          },
        }).then((rsp) => {
          _this.loading = false
          _this.totalPage = Math.ceil(rsp.count / _this.pageSize) //总页数
          _this.list = _this.list.concat(rsp.data)
          if(_this.pageIndex == _this.totalPage){
            _this.nomore = true
          }
        })
      },
      // 查看详情
      detail(item,index){
        this.$router.push({
          path:"/travelExpressDetail",
          query:{
            id: item.contentId,
            from: 'list'
          }
        })
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementById("app").scrollTop;
        let windowHeight = document.getElementById("app").clientHeight;
        let bodyHeight = document.getElementById("container") ? document.getElementById("container").clientHeight - 10 : 0;
        if (scrollTop + windowHeight + 30 >= bodyHeight && !this.loading && this.pageIndex < this.totalPage) {
          this.pageIndex = this.pageIndex + 1
          this.getList()
        }
      },
      //返回首页
      back() {
        this.$router.go(-1)
      }
    }
  };
</script>

<style scoped>
  .container{
    padding-top: 50px;
  }
  .top{
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 5px #ccc;
  }
  .top a{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: url(../../hotel/img/black_back.png) no-repeat center center/auto 18px;
    font-size: 0;
  }
  .top h1{
    font-size: 16px;
    line-height: 50px;
  }
  .my-swipe >>> .van-swipe-item .box {
    position: relative;
  }
  .my-swipe >>> .van-swipe-item .box a {
    display: block;
  }
  .my-swipe >>> .van-swipe-item h1 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.7);
    padding: 0 100px 0 10px;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.25s linear 0.2s;
    color: #fff;
  }
  .my-swipe >>> .van-swipe__indicators {
    bottom: 18px;
    right: 20px;
    left: auto;
    transform: translateX(0);
  }
  .my-swipe >>> .van-swipe__indicators i {
    transition: all 0.2s linear;
    border-radius: 0.16rem;
  }
  .my-swipe >>> .van-swipe__indicators i.van-swipe__indicator--active {
    width: 0.5rem;
  }
  .my-swipe >>> .van-image {
    position: static;
    display: block;
  }
  .list {
    font-size: 0;
    padding: 0 15px 15px 15px;
  }
  .list .blockname {
    font-size: 16px;
    height: 40px;
    line-height: 60px;
    text-align: left;
    color: #333;
  }
  .list a {
    margin-top: 10px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    display: block;
    padding: 5px;
    box-sizing: border-box;
    text-align: left;
  }
  .list a .img {
    width: 130px;
    height: 130px;
    display: inline-block;
    vertical-align: top;
  }
  .list a .text {
    width: calc(100% - 145px);
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }
  .list a .text .title {
    font-size: 15px;
    color: #333;
    line-height: 20px;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .list a .text .brief {
    font-size: 13px;
    color: #888;
    line-height: 20px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 5px 0;
  }
  .list a .text .time {
    text-align: right;
    font-size: 12px;
    color: #333;
    line-height: 20px;
  }
  .list .loading {
    font-size: 0.4rem;
    padding: 0.2rem;
    color: #ccc;
    text-align: center;
  }
  .list .loading img {
    height: 0.4rem;
    vertical-align: middle;
    margin: -0.1rem 0.1rem 0 0;
    animation: loading 0.5s linear infinite;
  }
  /*数据加载动画*/
  @keyframes loading {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(180deg);
    }
  }
  .nomore{
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    color: #888;
  }
</style>
